import React from 'react';

import Input from 'components/input';
import Button from 'components/button';
import { useSelector } from 'store/hooks';
import request from 'request';
import styles from './create-modal.module.scss';
import users from '../users';

const EditUserModal = ({
  onClose,
  onEdit,
  selectedUser,
}: {
  selectedUser?: users.user;
  onClose: () => void;
  onEdit: () => void;
}) => {
  console.log(selectedUser);
  const user = useSelector((state) => state.user);
  const [firstName, setFirstName] = React.useState(selectedUser?.first_name ?? '');
  const [lastName, setLastName] = React.useState(selectedUser?.last_name ?? '');
  const [email, setEmail] = React.useState(selectedUser?.email ?? '');
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [role, setRole] = React.useState({
    key: selectedUser?.role ?? '',
    value: '',
  });
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: 'email' | 'password1' | 'password2' | 'role' | 'firstname' | 'lastname';
    value: string;
  } | null>(null);

  const handleEdit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!firstName.trim()) {
      return setValidError({
        name: 'firstname',
        value: 'Имя обязательно для заполнения.',
      });
    } else if (!lastName.trim()) {
      return setValidError({
        name: 'email',
        value: 'Фамилия обязательно для заполнения.',
      });
    } else if (email.trim().length < 5) {
      return setValidError({
        name: 'email',
        value: 'Минимальная длина логина 5 символов',
      });
    } else if (password1.trim().length < 6) {
      return setValidError({
        name: 'password1',
        value: 'Минимальная длина пароля 6 символов',
      });
    } else if (password1.trim() !== password2.trim()) {
      return setValidError({
        name: 'password2',
        value: 'Пароли не совпадают',
      });
    } else if (role.key === '') {
      return setValidError({
        name: 'role',
        value: 'Пароли не совпадают',
      });
    } else setValidError(null);
    setPending(true);

    let body: any = {};
    if (password1.trim() !== '') body.password = password1;
    body.email = email.toLowerCase();
    body.role = role.key;
    body.first_name = firstName;
    body.last_name = lastName;

    request
      .put('/admin/users/' + selectedUser?.id, body)
      .then(() => onEdit())
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        onSubmit={handleEdit}
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>Редактировать пользователя</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <div className={styles.input}>
              <Input
                title="Имя"
                type="string"
                value={firstName}
                onInput={(value) => setFirstName(value)}
              />
              {validError?.name === 'firstname' && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
            <div className={styles.input}>
              <Input
                title="Фамилия"
                type="string"
                value={lastName}
                onInput={(value) => setLastName(value)}
              />
              {validError?.name === 'lastname' && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
            <Input
              title="Эл. почта"
              type="string"
              value={email}
              onInput={(value) => setEmail(value)}
            />
            {validError?.name === 'email' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>

          <div className={styles.input}>
            <Input
              title="Пароль"
              type="password"
              value={password1}
              onInput={(value) => setPassword1(value)}
            />
            {validError?.name === 'password1' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Подтверждение пароля"
              type="password"
              value={password2}
              onInput={(value) => setPassword2(value)}
            />
            {validError?.name === 'password2' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          {user.role === 'root' && (
            <div className={styles.input}>
              <Input
                id="role"
                title="Роль"
                type="select"
                value={role.key}
                onSelect={(option) => setRole(option)}
                options={[
                  {
                    key: 'expert',
                    value: 'Эксперт',
                  },
                  {
                    key: 'manager',
                    value: 'Менеджер',
                  },
                ]}
              />
              {validError?.name === 'role' && (
                <div className={styles.inputError}>{validError.value}</div>
              )}
            </div>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Редактировать
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditUserModal;
