import global from 'global';
import styles from './styles.module.scss';
import Button from 'components/button';
import { useState } from 'react';
import classNames from 'classnames';
import request from 'request';
import { toast } from 'react-toastify';
import ButtonLoader from 'components/button-loader';
import { useDispatch } from 'react-redux';

const ModalRec = ({
  onClose,
  recData,
}: {
  onClose: () => void;
  recData: global.recommendations_log[];
}) => {
  const dispatch = useDispatch();
  const [select, setSelect] = useState<global.recommendations_log | null>(null);
  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);
  function onSelect(rec: global.recommendations_log) {
    setSelect(rec);
    setError('');
  }

  function submit() {
    if (select === null) {
      setError('Выберите рекомендацию для принятия');
      return;
    }
    setPending(true);
    request
      .post(`/applications/${select.application_id}/recommendation/${select.id}`)
      .then((res) => res.data)
      .then(() => {
        setTimeout(() => dispatch.application.FETCH_APPLICATION_BY_ID(select.application_id), 1000);
      })
      .catch((e) => {
        toast.error('Заявка с такими данными не найдена');
      })
      .finally(() => setPending(false));
  }

  return (
    <div onMouseDown={onClose} className={styles.modal}>
      <div className={styles.modalWrap} onMouseDown={(event) => event.stopPropagation()}>
        <div className={styles.title}>Рекомендации к заявке</div>

        <div className={styles.items}>
          {recData.map((rec, i) => (
            <div
              key={i}
              className={classNames({
                [styles.item]: true,
                [styles.item__active]: select?.id === rec.id,
              })}
              onClick={() => onSelect(rec)}
            >
              <div className={styles.item__title}>Рекомендация {i + 1}</div>
              <div className={styles.item__desc}>
                <p>Сумма</p>
                <p>{rec.amount} TJS</p>
              </div>
              <div className={styles.item__desc}>
                <p>Длительность</p>
                <p>{rec.duration} мес.</p>
              </div>
              <div className={styles.item__desc}>
                <p>Вероятность выплаты</p>
                <p>{(rec.prob * 100).toFixed(1)}%</p>
              </div>
            </div>
          ))}
        </div>

        <p className={styles.validation_text}>{error}</p>

        <div className={styles.button}>
          <Button onClick={submit}>{pending ? <ButtonLoader /> : 'Подтверить'}</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalRec;
