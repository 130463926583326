import Button from 'components/button';
import Input from 'components/input';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import request from 'request';
import styles from './create-modal.module.scss';

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [sum, setSum] = React.useState('');
  const [inn, setInn] = React.useState('');
  const [interestRate, setInterestRate] = React.useState('');
  const [duration, setDuration] = React.useState('');
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: 'inn' | 'sum' | 'interestRate' | 'duration';
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inn.trim().length <= 3) {
      return setValidError({
        name: 'inn',
        value: 'ИНН должен быть длиннее 3 символов',
      });
    } else if (sum.trim().length === 0) {
      return setValidError({
        name: 'sum',
        value: 'Сумма обязательна для заполнения',
      });
    } else if (interestRate.trim().length === 0) {
      return setValidError({
        name: 'interestRate',
        value: 'Процентная ставка обязательна для заполнения',
      });
    } else if (duration.trim().length === 0) {
      return setValidError({
        name: 'duration',
        value: 'Длительность обязательна для заполнения',
      });
    } else setValidError(null);
    setPending(true);
    request
      .post('/applications', {
        inn,
        amount: sum,
        int_rate: interestRate,
        duration,
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/scoring`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>Новая заявка</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="ИНН"
              type="string"
              value={inn}
              onInput={(value) => {
                if (value.length > 12) return;
                setInn(value);
              }}
              placeholder={'ИНН клиента'}
            />
            {validError?.name === 'inn' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>

          <div className={styles.input}>
            <Input
              title="Сумма"
              type="integer"
              value={sum}
              onInput={(value) => setSum(value)}
              step={100}
              min={500}
              max={30000}
              placeholder={'мин: 500 макс: 30000'}
              unit="TJS"
            />
            {validError?.name === 'sum' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Процентная ставка"
              type="float"
              value={interestRate}
              onInput={(value) => setInterestRate(value)}
              step={1}
              min={0}
              max={48}
              placeholder={'мин: 0 макс: 48'}
              unit="%"
            />
            {validError?.name === 'interestRate' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Длительность"
              type="float"
              value={duration}
              onInput={(value) => setDuration(value)}
              step={1}
              min={3}
              max={36}
              placeholder={'мин: 3 макс: 36'}
              unit="мес."
            />
            {validError?.name === 'duration' && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Создать
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
