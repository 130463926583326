import React from 'react';
import * as math from 'mathjs';

import Range from 'components/range';
import rangebox from './range-box';
import styles from './range-box.module.scss';

const RangeBox: React.FC<rangebox.props> = ({ value, setValue }) => {
  return (
    <div className={styles.rangeBox}>
      <Range min={1} max={100} step={1} value={value} setValue={setValue} />
      <input
        type="number"
        className={styles.input}
        value={value}
        step={1}
        min={0}
        max={100}
        placeholder={'мин: 0 макс: 100'}
        onFocus={(event) => event.target.select()}
        onChange={(event) => setValue(math.fix(math.max(0, math.min(100, +event.target.value)), 2))}
      />
    </div>
  );
};

export default RangeBox;
