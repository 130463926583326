import { TRootState } from '../models';

const initialState = {
  get: () => {
    const initalState = localStorage.getItem('states');
    return initalState ? JSON.parse(initalState) : {};
  },
  set: (state: Partial<TRootState>) => {
    localStorage.setItem('states', JSON.stringify(state));
  },
};

export default initialState;
