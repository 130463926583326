import classNames from 'classnames';
import Button from 'components/button';
import Input from 'components/input';
import Loading from 'components/loading';
import global from 'global';
import Icon from 'icons';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import request from 'request';
import { useDispatch, useSelector } from 'store/hooks';
import prettierNumber from 'utils/prettier-number';
import transcript from 'utils/transcript';
import styles from './scoring.module.scss';
import stylesModal from './component/styles.module.scss';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import ModalRec from './component/rec';

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const [microdata, setMicrodata] = React.useState<global.macrodata>();
  const [rec, setRec] = React.useState(false);

  const [pending, setPending] = React.useState(false);

  const [age, setAge] = React.useState('');
  const [income, setIncome] = React.useState('');
  const [dependents, setDependents] = React.useState('');
  const [creditHistory, setCreditHistory] = useState('');

  const [workExperience, setWorkExperience] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('+992');

  const [disaster, setDisaster] = useState({
    key: '',
    value: '',
  });
  const [sector, setSector] = useState({
    key: '',
    value: '',
  });
  const [gender, setGender] = React.useState({
    key: '',
    value: '',
  });
  const [maritalStatus, setMaritalStatus] = React.useState({
    key: '',
    value: '',
  });
  const [education, setEducation] = React.useState({
    key: '',
    value: '',
  });
  const [phoneOS, setPhoneOS] = React.useState({
    key: '',
    value: '',
  });
  const [transport, setTransport] = React.useState({
    key: '',
    value: '',
  });
  const [hasHouse, setHasHouse] = React.useState({
    key: '',
    value: '',
  });
  const [workPlace, setWorkPlace] = React.useState({
    key: '',
    value: '',
  });
  const [bkitScore, setBkitScore] = React.useState({
    key: '',
    value: '',
  });
  const [jobStatus, setJobStatus] = React.useState({
    key: '',
    value: '',
  });
  const [district, setDistrict] = React.useState({
    key: '',
    value: '',
  });
  const [backspace, setBackspace] = useState(false);

  const [validation, setValidation] = useState<any>();
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<global.recommendations_log | null>(null);

  const validationText = 'Это поле обязательно';

  React.useEffect(() => {
    request
      .get('/admin/constant_values')
      .then((res) => res.data)
      .then((d) => setMicrodata(d));
  }, []);

  React.useEffect(() => {
    const rec = contextApplication.data?.recommendations_log;
    if (!rec) return;
    const find = rec.find((e) => e.is_chosen);
    if (!find) return;
    setSelectedRecommendation(find);
  }, [contextApplication]);

  const validateInputs = () => {
    const validationErrors: { [key: string]: string } = {};
    if (!age.trim()) validationErrors.age = validationText;
    if (!income.trim()) validationErrors.income = validationText;
    if (!dependents.trim()) validationErrors.dependents = validationText;
    if (!creditHistory.trim()) validationErrors.creditHistory = validationText;
    if (!workExperience.trim()) validationErrors.workExperience = validationText;
    if (!district.value.trim()) validationErrors.district = validationText;
    if (!disaster.value.trim()) validationErrors.disaster = validationText;
    if (!sector.value.trim()) validationErrors.sector = validationText;
    if (!gender.value.trim()) validationErrors.gender = validationText;
    if (!maritalStatus.value.trim()) validationErrors.maritalStatus = validationText;
    if (!education.value.trim()) validationErrors.education = validationText;
    if (!phoneOS.value.trim()) validationErrors.phoneOS = validationText;
    if (!transport.value.trim()) validationErrors.transport = validationText;
    if (!hasHouse.value.trim()) validationErrors.hasHouse = validationText;
    if (!workPlace.value.trim()) validationErrors.workPlace = validationText;
    if (!bkitScore.value.trim()) validationErrors.bkitScore = validationText;
    if (!jobStatus.value.trim()) validationErrors.jobStatus = validationText;
    if (!phoneNumber.trim() || phoneNumber.length < 12)
      validationErrors.phoneNumber = validationText;
    setValidation(validationErrors as any);
    return Object.keys(validationErrors).length === 0;
  };

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;

    const resultValidation = validateInputs();
    if (!resultValidation) return;

    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        age: Number(age),
        gender: gender.key,
        credit_history_count: Number(creditHistory),
        dependants: Number(dependents),
        sector: sector.key,
        marital_status: maritalStatus.key,
        district: district.key,
        education: education.key,
        threshold: microdata?.threshold,
        is_disaster: disaster.key,
        monthly_income: Number(income),
        phone_type: phoneOS.key,
        has_transport: transport.key,
        has_house: hasHouse.key,
        job_status: jobStatus.key,
        bkit_score: bkitScore.key,
        work_place: workPlace.key,
        work_experience: Number(workExperience),
        phone_number: phoneNumber.length === 16 ? phoneNumber : '',
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(() => dispatch.application.FETCH_APPLICATION_BY_ID(id), 1000);
      })
      .catch((e) => {
        toast.error('Заявка с такими данными не найдена');
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  const handleClear = () => {
    setGender({
      key: '',
      value: '',
    });
    setMaritalStatus({
      key: '',
      value: '',
    });
    setPhoneOS({ key: '', value: '' });
    setTransport({ key: '', value: '' });
    setHasHouse({ key: '', value: '' });
    setWorkPlace({ key: '', value: '' });
    setJobStatus({ key: '', value: '' });
    setSector({ key: '', value: '' });
    setEducation({ key: '', value: '' });
    setDisaster({ key: '', value: '' });
    setBkitScore({ key: '', value: '' });
    setDistrict({ key: '', value: '' });
    setAge('');
    setWorkExperience('');
    setIncome('');
    setDependents('');
    setCreditHistory('');
    setPhoneNumber('');
  };

  return (
    <form className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Данные кредита</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>ИНН</div>
              <div className={styles.value}>{contextApplication.data?.inn}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Сумма</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.amount ?? 0)} TJS
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Длительность</div>
              <div className={styles.value}>{contextApplication.data?.duration} мес.</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Процентная ставка</div>
              <div className={styles.value}>
                {Number(contextApplication.data?.int_rate).toFixed(2)} %
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Порог</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.threshold
                  ? (contextApplication.data?.scoring_log?.threshold * 100).toFixed()
                  : Math.round((microdata?.threshold ?? 0) * 100)}{' '}
                %
              </div>
            </div>
            {!!contextApplication.data?.scoring_log && (
              <>
                <div className={styles.item}>
                  <div className={styles.key}>Цель кредита</div>
                  <div className={styles.value}>
                    {transcript.workingAreaEng.getKeyFromValue(
                      contextApplication.data?.scoring_log?.sector,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Город/Район</div>
                  <div className={styles.value}>
                    {transcript.district.getValueFromKey(
                      contextApplication.data.scoring_log.district,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Количество кредитных историй</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.credit_history_count}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Оценка БКИТ</div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log?.bkit_score === 'none'
                      ? 'Нет'
                      : contextApplication.data?.scoring_log?.bkit_score.toUpperCase()}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Кредит во время экстренного периода</div>
                  <div className={styles.value}>
                    {transcript.isRealEstateCode.getKeyFromValue(
                      contextApplication.data?.scoring_log?.is_disaster,
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {!!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Данные клиента</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Возраст</div>
                  <div className={styles.value}>{contextApplication.data.scoring_log.age}</div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Пол</div>
                  <div className={styles.value}>
                    {transcript.gender.getValueFromKey(contextApplication.data.scoring_log.gender)}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Семейное положение</div>
                  <div className={styles.value}>
                    {transcript.maritalStatusEng.getValueFromKey(
                      contextApplication.data.scoring_log.marital_status,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Образование</div>
                  <div className={styles.value}>
                    {transcript.educationEng.getKeyFromValue(
                      contextApplication.data.scoring_log.education,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Месячный доход клиента</div>
                  <div className={styles.value}>
                    {prettierNumber(contextApplication.data.scoring_log.monthly_income)} TJS
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Кол-во иждивенцев </div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.dependants}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Наличие транспорта у клиента</div>
                  <div className={styles.value}>
                    {transcript.transcript.getValueFromKey(
                      contextApplication.data.scoring_log.has_transport,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Текущий рабочий статус</div>
                  <div className={styles.value}>
                    {transcript.workStatus.getValueFromKey(
                      contextApplication.data?.scoring_log?.job_status,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Место работы клиента</div>
                  <div className={styles.value}>
                    {transcript.workPlace.getValueFromKey(
                      contextApplication.data?.scoring_log?.work_place,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Наличие жилья у клиента</div>
                  <div className={styles.value}>
                    {transcript.havHouse.getValueFromKey(
                      contextApplication.data?.scoring_log?.has_house,
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Опыт работы в годах</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.work_experience}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Вид телефона у клиента</div>
                  <div className={styles.value}>
                    {transcript.phone_type(
                      contextApplication.data.scoring_log.phone_type.toUpperCase(),
                    )}
                  </div>
                </div>
                {contextApplication.data.scoring_log.phone_number.length === 16 && (
                  <div className={styles.item}>
                    <div className={styles.key}>Номер телефона клиента</div>
                    <div className={styles.value}>
                      {contextApplication.data.scoring_log.phone_number}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.title_for_section}>Данные кредита</div>
              <div className={styles.fieldList}>
                <Input
                  title="Цель кредита "
                  value={sector.key}
                  step={1}
                  type="select"
                  options={[
                    { key: 'agriculture', value: 'Сельское хозяйство' },
                    { key: 'consumer', value: 'Потребительский' },
                    { key: 'mortgage', value: 'Ипотечный' },
                    { key: 'production', value: 'Производство' },
                    { key: 'services', value: 'Услуги' },
                    { key: 'trade', value: 'Торговля' },
                    { key: 'retail', value: 'Розничная торговля' },
                  ]}
                  onSelect={(option) => setSector(option)}
                  error={validation?.sector}
                />
                <Input
                  title="Город/Район"
                  id="district"
                  value={district.key}
                  type="select"
                  options={[
                    { key: 'asht', value: 'Ашт' },
                    { key: 'b_gafurov', value: 'Б.Гафуров' },
                    { key: 'bokhtar', value: 'Бохтар' },
                    { key: 'dangara', value: 'Дангара' },
                    { key: 'devashtich', value: 'Деваштич' },
                    { key: 'dushanbe', value: 'Душанбе' },
                    { key: 'dusti', value: 'Дусти' },
                    { key: 'farkhor', value: 'Фархор' },
                    { key: 'farovon', value: 'Фаровон' },
                    { key: 'hamadoni', value: 'Хамадони' },
                    { key: 'hissor', value: 'Гиссар' },
                    { key: 'isfara', value: 'Исфара' },
                    { key: 'istaravshan', value: 'Истаравшан' },
                    { key: 'balkhi', value: 'Дж.Балхи' },
                    { key: 'rasulov', value: 'Дж.Расулов' },
                    { key: 'jayhun', value: 'Джайхун' },
                    { key: 'jomi', value: 'Джоми' },
                    { key: 'khujand', value: 'Худжанд' },
                    { key: 'khuroson', value: 'Хуросон' },
                    { key: 'konibodom', value: 'Канибадам' },
                    { key: 'qubodiyon', value: 'Кубодийон' },
                    { key: 'kulob', value: 'Куляб' },
                    { key: 'mastchoh', value: 'Мастчох' },
                    { key: 'muminabad', value: 'Муминобод' },
                    { key: 'panj', value: 'Пандж' },
                    { key: 'panjakent', value: 'Пенджикент' },
                    { key: 'rudaki', value: 'Рудаки' },
                    { key: 'shahriston', value: 'Шахристон' },
                    { key: 'shahrituz', value: 'Шахритуз' },
                    { key: 'somon', value: 'Сомон' },
                    { key: 'spitamen', value: 'Спитамен' },
                    { key: 'temurmalik', value: 'Темурмалик' },
                    { key: 'tursunzoda', value: 'Турсунзода' },
                    { key: 'vahdat', value: 'Вахдат' },
                    { key: 'vose', value: 'Восеъ' },
                    { key: 'yovon', value: 'Евон' },
                    { key: 'zafarobod', value: 'Зафаробод' },
                    { key: 'norak', value: 'Нурек' },
                    { key: 'buston', value: 'Бустон' },
                    { key: 'shahrinav', value: 'Шахринав' },
                    { key: 'nawkat', value: 'Навкат' },
                    { key: 'vakhsh', value: 'Вахш' },
                    { key: 'korvon', value: 'Корвон' },
                    { key: 'faizabad', value: 'Файзобод' },
                    { key: 'panjshanbe', value: 'Панджакент' },
                    { key: 'nosiri_khusrav', value: 'Носири Хусрав' },
                  ]}
                  onSelect={(option) => setDistrict(option)}
                  error={validation?.district}
                />
                <Input
                  title="Количество кредитных историй"
                  value={creditHistory}
                  min={0}
                  max={50}
                  step={1}
                  placeholder={'мин: 0 макс: 50'}
                  type="integer"
                  onInput={(value) => setCreditHistory(value)}
                  error={validation?.creditHistory}
                />

                <Input
                  title="Оценка БКИТ"
                  id="bkit_score"
                  value={bkitScore.key}
                  type="select"
                  options={[
                    {
                      key: 'a',
                      value: 'A',
                    },
                    {
                      key: 'b',
                      value: 'B',
                    },
                    {
                      key: 'c',
                      value: 'C',
                    },
                    {
                      key: 'd',
                      value: 'D',
                    },
                    {
                      key: 'e',
                      value: 'E',
                    },
                    {
                      key: 'f',
                      value: 'F',
                    },
                    {
                      key: 'g',
                      value: 'G',
                    },
                    {
                      key: 'h',
                      value: 'H',
                    },
                    {
                      key: 'i',
                      value: 'I',
                    },
                    {
                      key: 'j',
                      value: 'J',
                    },
                    {
                      key: 'k',
                      value: 'K',
                    },
                    {
                      key: 'l',
                      value: 'L',
                    },
                    {
                      key: 'm',
                      value: 'M',
                    },
                    {
                      key: 'n',
                      value: 'N',
                    },
                    {
                      key: 'o',
                      value: 'O',
                    },
                    {
                      key: 'p',
                      value: 'P',
                    },
                    {
                      key: 'q',
                      value: 'Q',
                    },
                    {
                      key: 'r',
                      value: 'R',
                    },
                    {
                      key: 's',
                      value: 'S',
                    },
                    {
                      key: 't',
                      value: 'T',
                    },
                    {
                      key: 'u',
                      value: 'U',
                    },
                    {
                      key: 'v',
                      value: 'V',
                    },
                    {
                      key: 'w',
                      value: 'W',
                    },
                    {
                      key: 'x',
                      value: 'X',
                    },
                    {
                      key: 'y',
                      value: 'Y',
                    },
                    {
                      key: 'z',
                      value: 'Z',
                    },
                    {
                      key: 'none',
                      value: 'Нет',
                    },
                  ]}
                  onSelect={(option) => setBkitScore(option)}
                  error={validation?.bkitScore}
                />
                <Input
                  title="Кредит во время экстренного периода"
                  value={disaster.key}
                  type="select"
                  options={[
                    { key: 'yes', value: 'Да' },
                    { key: 'no', value: 'Нет' },
                  ]}
                  error={validation?.disaster}
                  onSelect={(option) => setDisaster(option)}
                />
              </div>
            </div>

            <div className={styles.fieldGroup}>
              <div className={styles.title_for_section}>Данные клиента</div>
              <div className={styles.fieldList}>
                <Input
                  title="Возраст"
                  value={age}
                  min={18}
                  max={65}
                  step={1}
                  placeholder={'мин: 18 макс: 65'}
                  type="integer"
                  onInput={(value) => setAge(value)}
                  error={validation?.age}
                />
                <Input
                  title="Пол"
                  id="gender"
                  value={gender.key}
                  type="select"
                  options={[
                    {
                      key: 'female',
                      value: 'Женский',
                    },
                    {
                      key: 'male',
                      value: 'Мужской',
                    },
                  ]}
                  onSelect={(option) => setGender(option)}
                  error={validation?.gender}
                />
                <Input
                  title="Семейное положение"
                  id="maritalStatus"
                  value={maritalStatus.key}
                  type="select"
                  options={[
                    {
                      key: 'single',
                      value: 'Холост/Не замужем',
                    },
                    {
                      key: 'married',
                      value: 'Женат/Замужем',
                    },
                    {
                      key: 'widow_widower',
                      value: 'Вдовец/Вдова',
                    },
                    {
                      key: 'divorced',
                      value: 'Разведен(-а)',
                    },
                  ]}
                  error={validation?.maritalStatus}
                  onSelect={(option) => setMaritalStatus(option)}
                />
                <Input
                  title="Кол-во иждивенцев"
                  value={dependents}
                  step={1}
                  min={0}
                  max={100}
                  placeholder={'мин: 0 макс: 100'}
                  type="integer"
                  onInput={(value) => setDependents(value)}
                  error={validation?.dependents}
                />
                <Input
                  title="Образование"
                  id="education"
                  value={education.key}
                  type="select"
                  options={[
                    {
                      key: 'higher_education',
                      value: 'Высшее',
                    },
                    {
                      key: 'incomplete_secondary_education',
                      value: 'Неполное среднее',
                    },
                    {
                      key: 'secondary_education',
                      value: 'Среднее',
                    },
                    {
                      key: 'secondary_education_with_specialisation',
                      value: 'Среднее специальное',
                    },
                    {
                      key: 'Other Education',
                      value: 'Другое',
                    },
                  ]}
                  error={validation?.education}
                  onSelect={(option) => setEducation(option)}
                />
                <Input
                  title="Месячный доход клиента"
                  value={income}
                  step={100}
                  min={500}
                  placeholder={'мин: 500'}
                  type="integer"
                  onInput={(value) => setIncome(value)}
                  unit="TJS"
                  error={validation?.income}
                />
                <Input
                  title="Текущий рабочий статус"
                  id="jobStatus"
                  value={jobStatus.key}
                  type="select"
                  options={[
                    {
                      key: 'employed',
                      value: 'Трудоустроен',
                    },
                    {
                      key: 'unemployed',
                      value: 'Безработный',
                    },
                    {
                      key: 'other',
                      value: 'Другое',
                    },
                  ]}
                  error={validation?.jobStatus}
                  onSelect={(option) => setJobStatus(option)}
                />
                <Input
                  title="Место работы клиента"
                  id="work_place"
                  value={workPlace.key}
                  type="select"
                  options={[
                    {
                      key: 'government_agency',
                      value: 'Государственное учреждение',
                    },
                    {
                      key: 'public_organization',
                      value: 'Общественная организация',
                    },
                    {
                      key: 'private_company',
                      value: 'Частная компания',
                    },
                    {
                      key: 'individual_entrepreneur',
                      value: 'Индивидуальный предприниматель',
                    },
                    {
                      key: 'other',
                      value: 'Другое',
                    },
                  ]}
                  error={validation?.workPlace}
                  onSelect={(option) => setWorkPlace(option)}
                />

                <Input
                  title="Опыт работы в годах"
                  value={workExperience}
                  step={1}
                  min={0}
                  max={100}
                  placeholder={'мин: 0 макс: 100'}
                  type="integer"
                  error={validation?.workExperience}
                  onInput={(value) => setWorkExperience(value)}
                />
                <Input
                  title="Наличие транспорта у клиента"
                  id="transport"
                  value={transport.key}
                  type="select"
                  options={[
                    {
                      key: 'rent',
                      value: 'Аренда',
                    },
                    {
                      key: 'ownership',
                      value: 'Собственный',
                    },
                    {
                      key: 'on_credit',
                      value: 'В кредит',
                    },
                    {
                      key: 'other',
                      value: 'Другое',
                    },
                    {
                      key: 'no_transport',
                      value: 'Отсутствует',
                    },
                  ]}
                  error={validation?.transport}
                  onSelect={(option) => setTransport(option)}
                />
                <Input
                  title="Наличие жилья у клиента"
                  id="residenceType"
                  value={hasHouse.key}
                  type="select"
                  options={[
                    {
                      key: 'rent',
                      value: 'Аренда',
                    },
                    {
                      key: 'ownership',
                      value: 'В собственности',
                    },
                    {
                      key: 'on_credit',
                      value: 'В кредит',
                    },
                    {
                      key: 'other',
                      value: 'Другое',
                    },
                    {
                      key: 'no_house',
                      value: 'Отсутствует',
                    },
                  ]}
                  error={validation?.hasHouse}
                  onSelect={(option) => setHasHouse(option)}
                />
                <Input
                  title="Вид телефона у клиента"
                  id="phoneOS"
                  value={phoneOS.key}
                  type="select"
                  options={[
                    {
                      key: 'ios',
                      value: 'IOS',
                    },
                    {
                      key: 'android',
                      value: 'Android',
                    },
                    {
                      key: 'no_phone',
                      value: 'Отсутствует',
                    },
                    {
                      key: 'other',
                      value: 'Другое',
                    },
                  ]}
                  error={validation?.phoneOS}
                  onSelect={(option) => setPhoneOS(option)}
                />

                <Input
                  title="Номер телефона клиента"
                  value={phoneNumber}
                  type="string"
                  placeholder={'+992-XX-XXX-XXXX'}
                  error={validation?.phoneNumber}
                  onKeyDown={(event) => {
                    if (event.keyCode === 8) {
                      setBackspace(true);
                    } else {
                      setBackspace(false);
                    }
                  }}
                  onInput={(value) => {
                    if (value[0] === '+' && value.length < 17) {
                      if (!backspace) {
                        if (value.length > 4 && value[4] !== '-') {
                          value = value.slice(0, 4) + '-' + value.slice(4);
                        }
                        if (value.length > 7 && value[7] !== '-') {
                          value = value.slice(0, 7) + '-' + value.slice(7);
                        }
                        if (value.length > 11 && value[11] !== '-') {
                          value = value.slice(0, 11) + '-' + value.slice(11);
                        }
                      }

                      if (Number(value.substring(1).replaceAll('-', ''))) {
                        setPhoneNumber(value.trim());
                      } else if (value.length === 1) {
                        setPhoneNumber(value.trim());
                      }
                    } else if (!value.substring(1).trim()) {
                      setPhoneNumber('+992');
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                <Button onClick={handleCalculate}>Рассчитать</Button>
                <Button onClick={handleClear} color="red">
                  Очистить
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.result}>
        {pending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        {!!contextApplication.data?.scoring_log && (
          <>
            {contextApplication.data.is_approved &&
              contextApplication.data.selected_recommendation &&
              selectedRecommendation && (
                <div className={styles.container_rec}>
                  <div className={styles.container_rec__title}>Выбранная рекомендация</div>
                  <div className={styles.container_rec__item}>
                    <p>Сумма</p>
                    <p>{selectedRecommendation.amount} TJS</p>
                  </div>
                  <div className={styles.container_rec__item}>
                    <p>Срок</p>
                    <p>{selectedRecommendation.duration} мес.</p>
                  </div>
                  <div className={styles.container_rec__item}>
                    <p>Вероятность выплаты</p>
                    <p>{(selectedRecommendation.prob * 100).toFixed(1)} %</p>
                  </div>
                </div>
              )}
            <div className={styles.resultBox}>
              <div className={styles.resultBox_title}>Статус заявки</div>
              <Icon
                size={120}
                name={
                  contextApplication.data.scoring_log.is_approved ? 'tickCircle' : 'closeFilled'
                }
                className={classNames(
                  contextApplication.data.scoring_log.is_approved
                    ? styles.resultBox_icon_success
                    : styles.resultBox_icon_error,
                )}
              />

              {selectedRecommendation && (
                <p className={styles.selected_rec_text}>Одобрено по рекомендации</p>
              )}
              <div className={styles.resultBox_resultText}>
                <p>{contextApplication.data.scoring_log.is_approved ? 'Одобрено' : 'Отказано'}</p>
                <p>
                  {selectedRecommendation
                    ? (selectedRecommendation.prob * 100).toFixed(1)
                    : contextApplication.data.scoring_log.prediction * 100}
                  %
                </p>
              </div>
              <div className={styles.resultBox_resultText}>
                <p>Порог</p>
                <p>{contextApplication.data.scoring_log.threshold * 100}%</p>
              </div>
              {contextApplication.data.recommendations_log.length > 0 &&
                !contextApplication.data.is_approved && (
                  <Button
                    type="button"
                    onClick={() => {
                      setRec(true);
                    }}
                  >
                    Рекомендации к заявке
                  </Button>
                )}
              <CSSTransition
                in={rec}
                timeout={150}
                unmountOnExit
                classNames={{
                  enter: stylesModal.modalEnter,
                  enterActive: stylesModal.modalEnterActive,
                  exit: stylesModal.modalExit,
                  exitActive: stylesModal.modalExitActive,
                }}
              >
                <ModalRec
                  onClose={() => setRec(false)}
                  recData={contextApplication.data.recommendations_log}
                />
              </CSSTransition>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Scoring;
