import React from 'react';
import IIcon from './icon';
import list from './svg-list';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Icon: React.FC<IIcon.props> = React.memo(
  ({ size = 24, name, className }): JSX.Element => (
    <div className={classNames(className, styles.icon)}>
      <svg
        viewBox="0 0 24 24"
        style={{ width: size + 'px', height: size + 'px' }}
        fill="currentColor"
      >
        {list[name]}
      </svg>
    </div>
  ),
  (prevProps, nextProps) => prevProps.name === nextProps.name && prevProps.size === nextProps.size,
);

export default Icon;
