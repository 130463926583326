import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Document, Page, View, Text, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';

import Icon from 'icons';
import global from 'global';
import transcript from 'utils/transcript';
import { useSelector } from 'store/hooks';
import styles from './result.module.scss';
import Loading from 'components/loading';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: '10px',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  section: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  heading: {
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  text: {
    fontSize: '12px',
  },
  table: {
    backgroundColor: '#fff',
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
  },
  td: {
    border: '1px solid #000',
    width: '100%',
    padding: '5px 10px',
    fontSize: '12px',
  },
  result: {
    fontSize: '15px',
    fontWeight: 900,
  },
  bottom: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gpt_text: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000',
  },
});

const Result = () => {
  const [shapLoading, setShapLoading] = React.useState(false);
  const contextApplication = useSelector((state) => state.application);
  const navigate = useNavigate();

  const [selectedRecommendation, setSelectedRecommendation] =
    React.useState<global.recommendations_log | null>(null);

  React.useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate('/');
  }, [contextApplication, navigate]);

  React.useEffect(() => {
    const rec = contextApplication.data?.recommendations_log;
    if (!rec) return;
    const find = rec.find((e) => e.is_chosen);
    if (!find) return;
    setSelectedRecommendation(find);
  }, [contextApplication]);

  return (
    <div className={styles.result}>
      <div className={styles.content}>
        <div className={styles.pdf}>
          <PDFViewer style={{ width: '100%', height: 'calc(100vh - 150px)' }}>
            <Pdf
              contextApplication={contextApplication}
              selectedRecommendation={selectedRecommendation}
            />
          </PDFViewer>
        </div>
        {contextApplication.data?.scoring_log?.shap_link && (
          <div className={styles.shap_value}>
            {shapLoading && (
              <div className={styles.loading_shap}>
                <Loading />
              </div>
            )}
            <div className={styles.shap_value_title}>Значения Шэпли для принятия решений</div>
            {contextApplication.data?.scoring_log && (
              <>
                <iframe
                  title="shap_link"
                  src={contextApplication.data?.scoring_log?.shap_link}
                  style={{ width: '100%' }}
                  onLoad={() => setShapLoading(false)}
                ></iframe>
              </>
            )}
            <div className={styles.gpt_text}>
              {contextApplication.data?.gpt_text &&
                contextApplication.data?.gpt_text.ru.split('\n').map((i, index) => (
                  <React.Fragment key={index}>
                    {i} <br />
                  </React.Fragment>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.resultBoxGroup}>
        {contextApplication?.data?.is_approved &&
          contextApplication.data.selected_recommendation &&
          selectedRecommendation && (
            <div className={styles.container_rec}>
              <div className={styles.container_rec__title}>Выбранная рекомендация</div>
              <div className={styles.container_rec__item}>
                <p>Сумма</p>
                <p>{selectedRecommendation.amount} TJS</p>
              </div>
              <div className={styles.container_rec__item}>
                <p>Срок</p>
                <p>{selectedRecommendation.duration} мес.</p>
              </div>
              <div className={styles.container_rec__item}>
                <p>Вероятность выплаты</p>
                <p>{(selectedRecommendation.prob * 100).toFixed(1)} %</p>
              </div>
            </div>
          )}
        {contextApplication.data && (
          <div className={styles.resultBox}>
            <div className={styles.resultBox_title}>Статус заявки</div>
            <Icon
              size={120}
              name={contextApplication.data.scoring_log.is_approved ? 'tickCircle' : 'closeFilled'}
              className={classNames(
                contextApplication.data.scoring_log.is_approved
                  ? styles.resultBox_icon_success
                  : styles.resultBox_icon_error,
              )}
            />

            {selectedRecommendation && (
              <p className={styles.selected_rec_text}>Одобрено по рекомендации</p>
            )}
            <div className={styles.resultBox_resultText}>
              <p>{contextApplication.data.scoring_log.is_approved ? 'Одобрено' : 'Отказано'}</p>
              <p>
                {selectedRecommendation
                  ? (selectedRecommendation.prob * 100).toFixed(1)
                  : contextApplication.data.scoring_log.prediction * 100}
                %
              </p>
            </div>
            <div className={styles.resultBox_resultText}>
              <p>Порог</p>
              <p>{contextApplication.data.scoring_log.threshold * 100}%</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
  selectedRecommendation,
}: {
  contextApplication: global.contextApplication;
  selectedRecommendation: global.recommendations_log | null;
}) => {
  return (
    <Document>
      {/* Скоринг */}
      {contextApplication.data?.scoring_log && (
        <>
          <Page style={pdfStyles.page}>
            <View style={pdfStyles.heading}>
              <Text>Результат скоринга</Text>
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.text}>
                Скоринг проведён экспертом: {contextApplication.data?.created_by.first_name ?? ''}{' '}
                {contextApplication.data?.created_by.last_name ?? ''} (
                {contextApplication.data?.created_by.email ?? ''}){' '}
              </Text>
              <Text style={pdfStyles.text}>
                Дата/время: {new Date(contextApplication.data?.created_at ?? '').toLocaleString()}
              </Text>
              <Text style={pdfStyles.text}>Заявка №{contextApplication.data?.id}</Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>ИНН</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.inn}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Сумма</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {selectedRecommendation
                        ? selectedRecommendation.amount
                        : contextApplication.data?.amount}{' '}
                      TJS
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Длительность</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {selectedRecommendation
                        ? selectedRecommendation.duration
                        : contextApplication.data?.duration}{' '}
                      мес.
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Процентная ставка</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{(contextApplication.data?.int_rate).toFixed(2)}%</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Порог</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.threshold * 100}%</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Возраст</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.age}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Количество кредитных историй</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.credit_history_count}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Цель кредита </Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.workingAreaEng.getKeyFromValue(
                        contextApplication.data?.scoring_log?.sector,
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Пол</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.gender.getValueFromKey(
                        contextApplication.data?.scoring_log?.gender,
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Город/Район</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.district.getValueFromKey(
                        contextApplication.data.scoring_log.district,
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Семейное положение</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.maritalStatusEng.getValueFromKey(
                        contextApplication.data?.scoring_log?.marital_status,
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Образование</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.educationEng.getKeyFromValue(
                        contextApplication.data.scoring_log.education,
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Месячный доход клиента</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.monthly_income} TJS</Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Кол-во иждивенцев</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.dependants}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Кредит во время экстренного периода</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.isRealEstateCode.getKeyFromValue(
                        contextApplication.data?.scoring_log?.is_disaster,
                      )}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Наличие транспорта у клиента</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.transcript.getValueFromKey(
                        contextApplication.data.scoring_log.has_transport,
                      )}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Текущий рабочий статус</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.workStatus.getValueFromKey(
                        contextApplication.data?.scoring_log?.job_status,
                      )}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Место работы клиента</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.workPlace.getValueFromKey(
                        contextApplication.data?.scoring_log?.work_place,
                      )}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Оценка БКИТ</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.bkit_score === 'none'
                        ? 'Нет'
                        : contextApplication.data?.scoring_log?.bkit_score.toUpperCase()}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Наличие жилья у клиента</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.havHouse.getValueFromKey(
                        contextApplication.data?.scoring_log?.has_house,
                      )}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Опыт работы в годах</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.work_experience}</Text>
                  </View>
                </View>

                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Вид телефона у клиента</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.phone_type(
                        contextApplication.data.scoring_log.phone_type.toUpperCase(),
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Номер телефона клиента</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.phone_number}</Text>
                  </View>
                </View>
              </View>
              <View style={pdfStyles.bottom}>
                <Text style={pdfStyles.result}>
                  Результат:{' '}
                  {contextApplication.data?.scoring_log.is_approved ? 'Одобрено' : 'Отказано'}{' '}
                  {selectedRecommendation
                    ? (selectedRecommendation.prob * 100).toFixed(1)
                    : contextApplication.data?.scoring_log?.prediction * 100}
                  %
                </Text>
                <Text style={pdfStyles.result}>
                  Порог: {Number(contextApplication.data?.scoring_log?.threshold) * 100}%
                </Text>
              </View>
            </View>
          </Page>
          <Page style={pdfStyles.page}>
            <View>
              <Text style={pdfStyles.gpt_text}>
                {contextApplication.data?.scoring_log.gpt_explanation}
              </Text>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
