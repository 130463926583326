import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import AppRoutes from 'routes';
import store from 'store';
import 'global.scss';
import { ToastContainer } from 'react-toastify';

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE,
};

const App = () => (
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AlertProvider>
    <ToastContainer />
  </Provider>
);

export default App;
