import React from 'react';
import { AxiosError } from 'axios';
import { useAlert } from 'react-alert';

import Button from 'components/button';
import RangeBox from 'components/range-box';
import request from 'request';
import styles from './settings.module.scss';

const Settings = () => {
  const alert = useAlert();
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);
  const [range, setRange] = React.useState(20);

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put('/admin/constant_values', {
        threshold: range / 100,
      })
      .then(() => alert.success('Сохранено!'))
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get('/admin/constant_values')
      .then((res) => res.data)
      .then((data) => {
        setRange(Math.round(data.threshold * 100));
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Порог</div>
          <RangeBox value={range} setValue={setRange} />
        </div>
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
