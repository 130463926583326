const transcript = {
  workingAreaEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case 'agriculture':
          return 'Сельское хозяйство';
        case 'consumer':
          return 'Потребительский';
        case 'mortgage':
          return 'Ипотечный';
        case 'production':
          return 'Производство';
        case 'services':
          return 'Услуги';
        case 'trade':
          return 'Торговля';
        case 'retail':
          return 'Розничная торговля';
        default:
          return text;
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'agriculture':
          return 'Agriculture';
        case 'animal_husbandry':
          return 'Livestock';
        case 'building':
          return 'Construction';
        case 'driver':
          return 'Driver';
        case 'employment':
          return 'Employer';
        case 'entrepreneur':
          return 'Individual entrepreneur';
        case 'housewife':
          return 'Housewife';
        case 'manufacture':
          return 'Production';
        case 'medicine':
          return 'Medicine';
        case 'military':
          return 'Military';
        case 'public_officer':
          return 'Civil servant';
        case 'retired':
          return 'Retired';
        case 'services':
          return 'Employment';
        case 'trade':
          return 'Trade';
        default:
          return text;
      }
    },
  },
  educationEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case 'higher_education':
          return 'Высшее';
        case 'incomplete_secondary_education':
          return 'Неполное среднее';
        case 'secondary_education':
          return 'Среднее';
        case 'secondary_education_with_specialisation':
          return 'Среднее специальное';
        case 'other_education':
          return 'Другое';
        default:
          return text;
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'higher_education':
          return 'Higher';
        case 'no_education':
          return 'Without education';
        case 'secondary_education':
          return 'Secondary specialty';
        default:
          return text;
      }
    },
  },
  gender: {
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case 'male':
          return 'Мужской';
        case 0:
        case 'female':
          return 'Женский';
        default:
          return text;
      }
    },
  },
  phone_type: (text: string) => {
    switch (text) {
      case 'IOS':
        return 'IOS';
      case 'Android':
        return 'Android';
      case 'NO_PHONE':
        return 'Отсутствует';
      case 'Other':
        return 'Другое';
      default:
        return text;
    }
  },
  maritalStatusEng: {
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 'divorced':
          return 'Разведен(-а)';
        case 'married':
          return 'Женат/Замужем';
        case 'single':
          return 'Холост/Не замужем';
        case 'widow_widower':
          return 'Вдова/вдовец';
        default:
          return text;
      }
    },
  },
  isRealEstateCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case 'yes':
          return 'Да';
        case 'no':
          return 'Нет';
        default:
          return text;
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case '1':
          return 'Yes';
        case 0:
        case '0':
          return 'No';
        default:
          return text;
      }
    },
  },
  coBorrowerCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case 'yes':
          return 1;
        case 'no':
          return 0;
        default:
          return text;
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case '1':
          return 'Yes';
        case 0:
        case '0':
          return 'No';
        default:
          return text;
      }
    },
  },
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'manager':
          return 'Менеджер';
        case 'root':
          return 'root';
        default:
          return 'Кредитный эксперт';
      }
    },
  },
  transcript: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'rent':
          return 'Аренда';
        case 'ownership':
          return 'Собственность';
        case 'on_credit':
          return 'В кредит';
        case 'other':
          return 'Другое';
        case 'no_transport':
          return 'Нет';
        default:
          return text;
      }
    },
  },
  workStatus: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'employed':
          return 'Трудоустроен';
        case 'unemployed':
          return 'Безработный';
        case 'other':
          return 'Другое';
        default:
          return text;
      }
    },
  },
  workPlace: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'government_agency':
          return 'Государственное учреждение';
        case 'private_company':
          return 'Частная компания';
        case 'public_organization':
          return 'Общественная организация';
        case 'individual_entrepreneur':
          return 'Индивидуальный предприниматель';
        case 'none':
          return 'Безработный';
        case 'other':
          return 'Другое';
        default:
          return text;
      }
    },
  },

  havHouse: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'rent':
          return 'Аренда';
        case 'ownership':
          return 'Собственность';
        case 'on_credit':
          return 'В кредит';
        case 'other':
          return 'Другое';
        case 'no_house':
          return 'Нет';
        default:
          return text;
      }
    },
  },
  district: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case 'asht':
          return 'Ашт';
        case 'b_gafurov':
          return 'Б.Гафуров';
        case 'bokhtar':
          return 'Бохтар';
        case 'dangara':
          return 'Дангара';
        case 'devashtich':
          return 'Деваштич';
        case 'dushanbe':
          return 'Душанбе';
        case 'dusti':
          return 'Дусти';
        case 'farkhor':
          return 'Фархор';
        case 'farovon':
          return 'Фаровон';
        case 'hamadoni':
          return 'Хамадони';
        case 'hissor':
          return 'Гиссар';
        case 'isfara':
          return 'Исфара';
        case 'istaravshan':
          return 'Истаравшан';
        case 'j_balkhi':
          return 'Дж.Балхи';
        case 'j.rasulov':
          return 'Дж.Расулов';
        case 'jayhun':
          return 'Джайхун';
        case 'jomi':
          return 'Джоми';
        case 'khujand':
          return 'Худжанд';
        case 'khuroson':
          return 'Хуросон';
        case 'konibodom':
          return 'Канибадам';
        case 'kubodiyon':
          return 'Кубодийон';
        case 'kulob':
          return 'Куляб';
        case 'mastchoh':
          return 'Мастчох';
        case 'muminobod':
          return 'Муминобод';
        case 'panj':
          return 'Пандж';
        case 'panjakent':
          return 'Пенджикент';
        case 'rudaki':
          return 'Рудаки';
        case 'shahriston':
          return 'Шахристон';
        case 'shahrituz':
          return 'Шахритуз';
        case 'somon':
          return 'Сомон';
        case 'spitamen':
          return 'Спитамен';
        case 'temurmalik':
          return 'Темурмалик';
        case 'tursunzoda':
          return 'Турсунзода';
        case 'vahdat':
          return 'Вахдат';
        case 'vose':
          return 'Восеъ';
        case 'yovon':
          return 'Евон';
        case 'zafarobod':
          return 'Зафаробод';
        case 'norak':
          return 'Нурек';
        case 'buston':
          return 'Бустон';
        case 'kurgan':
          return 'Курган';
        case 'shahrinav':
          return 'Шахринав';
        case 'nawkat':
          return 'Навкат';
        case 'vakhsh':
          return 'Вахш';
        case 'korvon':
          return 'Корвон';
        case 'faizabad':
          return 'Файзобод';
        case 'panjshanbe':
          return 'Панджакент';
        case 'nosiri_khusrav':
          return 'Носири Хусрав';
        default:
          return text;
      }
    },
  },
};

export default transcript;
