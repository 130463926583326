import React from 'react';
import classNames from 'classnames';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useSelector } from 'store/hooks';
import styles from './main.module.scss';
import VersionComponent from 'components/version';

const MainLayout = () => {
  const navigate = useNavigate();
  const [profileOpened, setProfileOpened] = React.useState(false);
  const user = useSelector((state) => state.user);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <Link to="/" className={styles.title}>
            Commerce bank | zypl.score <VersionComponent />
          </Link>
          <div className={classNames(styles.profileDropdown, profileOpened && styles.active)}>
            <button
              className={styles.profileDropdown__label}
              onClick={() => setProfileOpened(!profileOpened)}
            >
              {user.email}
            </button>
            {profileOpened && (
              <div className={styles.cover} onMouseDown={() => setProfileOpened(false)} />
            )}
            <CSSTransition
              unmountOnExit
              timeout={80}
              in={profileOpened}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <div className={styles.profileDropdown__menu}>
                {user.role !== 'expert' && (
                  <>
                    <Link
                      onClick={() => setProfileOpened(false)}
                      to="/users"
                      className={styles.profileDropdown__item}
                    >
                      Список пользователей
                    </Link>
                    {/* <button onClick={() => setProfileOpened(false)} className={styles.profileDropdown__item}>
                      Сменить пароль
                    </button> */}
                    <Link
                      onClick={() => setProfileOpened(false)}
                      to="/settings"
                      className={styles.profileDropdown__item}
                    >
                      Настройка
                    </Link>
                    <Link
                      onClick={() => setProfileOpened(false)}
                      to="/action-history"
                      className={styles.profileDropdown__item}
                    >
                      История действий
                    </Link>
                  </>
                )}
                <button className={styles.profileDropdown__item} onClick={handleLogout}>
                  Выход
                </button>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default MainLayout;
