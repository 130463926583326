import React from 'react';

import styles from './button-loader.module.scss';

const ButtonLoader = () => {
  return (
    <div className={styles.buttonLoader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonLoader;
